import React from 'react';
import { useTranslation } from 'react-i18next';

function Contacto() {

const { t } = useTranslation();

return (
    <div className="App">
    <div className="overlayt banim">
    <img className="img-fluid" src={require("../assets/img/Contactoban.png")} alt="contactobanner"/>
    <div className="bottom-left bo">{t('Enca.6')}</div>
      </div>
      
      <section>
      

      	<div className=" containerflex ">
           <div className="columna1" >
            <ul className="bo"> Ciudad de México</ul>
            <ul> Miguel Laurent 804, interior 6</ul>
            <ul> Col. Letrán Valle, C.P. 03650</ul>
            <ul> Alcaldía Cuauhtémoc, CDMX.</ul>    
            <ul> Tel. +52 5566502991</ul>   
          </div>

          <div className="columna1 " >
            <ul className="bo ">Veracruz</ul>
            <ul> Miguel Hidalgo 657-C</ul>
            <ul> Col. Centro, C.P. 91700</ul>
            <ul> Veracruz, Ver.</ul>
            <ul> Tel. +52 5566502991</ul>

          </div>
          

           <div className="columna1" >
            <ul className="bo "> Guadalajara</ul>
            <ul> Av. Chapalita 1081-A, oficina 5</ul>
            <ul> Col. Chapalita, C.P. 44500</ul>
            <ul> Guadalajara, Jal.</ul> 
            <ul> Tel. +52 3315955775</ul>      
          </div>

          

          </div>
          <div className=" containerflex marflex" >
          
          <div  className="marflex2">
            <ul className="bo"> Querétaro</ul>
            <ul> Blvd. Universitario 399, int. 21,</ul>
            <ul> oficinas 19 y 20</ul>
            <ul> Col. Juriquilla, C.P. 76230</ul>
            <ul> Querétaro, Qro.</ul>
            <ul> Tel. +52 5566502991</ul>    
          </div>

          <div className="marflex3 " >
            <ul className="bo "> Aeropuerto Ciudad de México (AICM)</ul>
            <ul> Aviación Militar 53</ul>
            <ul> Col. Ind. Puerto Aéreo, C.P. 15970</ul>
            <ul> Alcaldía Venustiano Carranza, CDMX.</ul>
            <ul> Tel. +52 5566502996</ul>       
          </div>

          <div></div>
		    </div>



        
      </section>
    


    	
    </div>


  );
}
  
export default Contacto;