import React from 'react';
import { useTranslation } from 'react-i18next';

function Pie() {

const { t } = useTranslation();

return (
    <div className="App">
      
    <footer className="footer">
    
      <div className="row align-items-center">
        
        <div className="col-md-3">          
        <ul className="list-inline quicklinks ">
          
          <a href="/#" className="list-inline-item text-white" data-toggle="modal" data-target="#exampleModalCenter">
            {t('Pie.1')}
          </a>
         
          </ul>

        <div className="modal fade" id="exampleModalCenter"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pie.1')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body text-justify">
              <p>Sus datos personales serán procesados y utilizados de conformidad con este Aviso de Privacidad y con lo establecido por la Ley 
              Federal de Protección de datos personales, así como con la normativa y disposiciones análogas y complementarias en materia de protección 
              de datos personales.</p>
              <p className="bo"> ¿Para qué recabamos sus datos personales?</p>
              Sus datos e información personal serán utilizados para las siguientes finalidades:
              <ul>(i) Enviarle información sobre las actividades que realizamos y temas de interés general relacionados con nuestra actividad 
              empresarial.</ul>
              <ul>(ii) Invitarle a participar en eventos y colaborar con nosotros, ya sea como voluntario, aliado o participando en otras
               actividades específicas.</ul>
              <ul>(iii) Crear bases de datos para fines administrativos.</ul>
              <ul>(iv) Atender cualquier solicitud, pregunta o comentario realizado por usted.</ul>
              <ul>(v) Enviarle notificaciones de modificaciones a este aviso de privacidad.</ul>
              <p>Hacemos de su conocimiento que en cualquier momento puedes revocar tu consentimiento, notificándolo por correo electrónico
               a <a href="mailto:cotizaciones@tocmx.com">cotizaciones@tocmx.com</a></p>
              <p className="bo">¿Cómo protegemos sus datos personales?</p>
              <p>Nos comprometemos a que sus datos personales serán tratados bajo las medidas de seguridad administrativas, físicas y técnicas 
              establecidas por la Ley y su Reglamento y así evitar su daño, pérdida, extravío, alteración y/o tratamiento no autorizado.</p>
              <p className="bo">¿Cómo puede limitar el uso o divulgación de tus datos personales?</p>
              <p>Tiene derecho de limitar el uso o divulgación de sus datos personales, por lo que, si ya no desea recibir nuestras comunicaciones,
              envíanos un correo electrónico a nuestros datos de contacto, indicándonos su situación.</p>
              <p className="bo">¿Con quién compartimos tus datos personales?</p>
              <p>Tus datos personales sólo serán compartidos cuando nos los requiera formalmente alguna autoridad en México o en el extranjero y 
              a cualquier otra persona autorizada por la Ley o su Reglamento. Salvo que la Ley nos exija lo contrario, solamente compartimos información
               de manera disociada, esto es: de forma anónima, sin identificarte, a efecto de brindarte mayor protección.</p>
              <p className="bo">Cambios al Aviso de Privacidad, Legislación y Jurisdicción</p>
              <p>Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente aviso de privacidad, 
              para la atención de novedades legislativas, políticas internas o nuevos requerimientos para la prestación u ofrecimiento de 
              nuestros productos.</p>
              <p> Cualquier modificación será comunicada vía correo electrónico y en nuestro sitio web. 
              <a href="http://tocmx.com/"> www.tocmx.com</a>.</p>
              <p className="bo">Autoridad</p>
              <p>Si considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de nuestros empleados o de
               nuestras acciones, presume que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la 
               Ley, podrá interponer la queja o denuncia correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y 
               Protección de datos personales, para consultar mayor información visite <a href="http://www.ifai.org.mx" target="_blank" rel="noopener noreferrer">www.ifai.org.mx.</a></p>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
            </div>
            </div>
            </div>
        </div>

        <div className="col-md-6 text-white"> 
            <div > Miguel Laurent 804 interior 6, Col. Letrán Valle, C.P. 03650, Alcaldía Benito Juárez, Ciudad de México</div>
            <div> Copyright &copy; 2021 {t('Pie.2')}.</div>        </div>

        <div className="col-md-3 text-white">
           <div>TEL + 52 5566502991</div>
          <ul className="list-inline social-buttons">
          <li className="list-inline-item">
              <a href="mailto:cotizaciones@tocmx.com" >
                <i className="fa fa-envelope"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="tel:5566502991">
                <i className="fa fa-phone"></i>
              </a>
            </li>
          <li className="list-inline-item ">
              <a href="https://api.whatsapp.com/send?phone=+524424790233&text=Me gustaría contactarme con un representante" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-whatsapp"></i>
              </a>
            </li>
          </ul>
        </div>
    </div>
    
  </footer>
    
</div>


  );
}
  
export default Pie;