import React from 'react';
import FormCont from './FormCont.js'
import { useTranslation } from 'react-i18next';

function Sustentabilidad() {

const { t } = useTranslation();

return (
    <div className="App">
      <div className="overlayt banim">
      <img className="img-fluid" src={require("../assets/img/sustban.png")} alt="bannersustentabilidad" />
      <div className="bottom-left bo">{t('Enca.5')}</div>
      </div>
    
    <section className="container2 align-self-center introsec">

          <p>{t('Su.1')}</p>
          <p>{t('Su.2')}</p>
          <p>{t('Su.3')}</p>

          <p className="bo"> {t('Su.4')}</p>
    </section>

    <section className="orange ">
    <div className="text-white mar">
    <h2 className="text-white mar"> {t('Su.5')}</h2>
    </div>
    </section>
    <section>
    <div className="d-flex p-4 align-items-center" id="gruposinteres">
    <div className="div3 text-justify">
    <h5>{t('Su.6')}</h5>
        <p>{t('Su.7')}</p>
    </div>
    <div className="div33">
      <img className="img-fluid" src={require("../assets/img/acci.png")} alt="accionistas"  />
    </div>

      <div className="div3 text-justify">
    <h5>{t('Su.14')}</h5>
       <p>{t('Su.15')}</p>
    </div>
    <div className="div33">
      <img className="img-fluid" src={require("../assets/img/colab.png")} alt="facelogo"  />
    </div>

    </div>

    </section>

    <section>
    <div className="d-flex p-4 align-items-center" id="clientes">
    <div className="div3 text-justify">
    <h5>{t('Su.8')}</h5>
        <p>{t('Su.9')}</p>
    </div>
    <div className="div33">
      <img className="img-fluid" src={require("../assets/img/cli.png")} alt="clientes" />
    </div>
    <div className="div3 text-justify">
   <h5>{t('Su.16')} </h5>
       <p>{t('Su.17')} </p>
        </div>

      <div className="div33">
      <img className="img-fluid" src={require("../assets/img/gobi.png")} alt="gobierno"  />
    </div>
    </div>

    </section>

    <section>
    <div className="d-flex p-4 align-items-center " id="colaboradores">
       <div className="div3 text-justify">
   <h5>{t('Su.10')} </h5>
        <p>{t('Su.11')}</p>
        </div>

      <div className="div33">
      <img className="img-fluid" src={require("../assets/img/comlo.png")} alt="comunidad"  />
    </div>
    <div className="div3 text-justify">
   <h4> Social</h4>
       <p>{t('Su.18')}</p>
        </div>

      <div className="div33">
      <img className="img-fluid" src={require("../assets/img/social.png")} alt="sociallogo"/>
    </div>
    </div>

    </section>

    <section>
    <div className="d-flex p-4 align-items-center" id="gruposinteres">
    <div className="div3 text-justify">
    <h5>{t('Su.12')}</h5>
        <p>{t('Su.13')}</p>
    </div>
    <div className="div33">
      <img className="img-fluid" src={require("../assets/img/comp.png")} alt="complogo"/>
    </div>
    <div className="div3 text-justify">
   <h5> {t('Su.19')}</h5>
       <p>{t('Su.20')}</p>
        </div>

      <div className="div33">
      <img className="img-fluid" src={require("../assets/img/prov.png")} alt="proveedores" />
    </div>
    </div>

    </section>

    <section className="orange introsec ">
      <h1 className="text-white mar bo"> {t('Pol.1')}</h1>
      <div className="text-white container ">
        <h3 className="bo tex"> {t('Pol.2')}</h3>
        <p className="text-justify">{t('Pol.3')}</p>

      <div className="d-flex p-2 mar align-items-center introsec" id="tiposerv">
        <div className="div7">
        <a href={require("../assets/pdf/POLITICADERESPONSABILIDADSOCIALEMPRESARIAL.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white " >{t('Pol.4')}</a>
        </div>
        <div className="div7">
        <a href={require("../assets/pdf/POLITICAIGUALDADDETRATO.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.5')}</a>
        </div>
      </div>
      <div className="d-flex p-2 mar" id="tiposerv">
        <div className=" div7">
        <a href={require("../assets/pdf/POLITICADERECHOSHUMANOS.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.6')}</a>
        </div>
        <div className=" div7">
        <a href={require("../assets/pdf/POLITICADEPROVEEDORES.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.7')}</a>
        </div>
      </div>
      <div className="d-flex p-2 mar" id="tiposerv">
        <div className=" div7">
        <a href={require("../assets/pdf/POLITICADEMEDIOAMBIENTE.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.8')}</a>
        </div>
        <div className=" div7">
        <a href={require("../assets/pdf/POLITICAETICAEMPRESARIAL.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.9')}</a>
        </div>
      </div>
      <div className="d-flex p-2 mar" id="tiposerv">
        <div className="div7">
        <a href={require("../assets/pdf/POLITICADEVINCULACIONALACOMUNIDAD.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.10')}</a>
        </div>
        <div className="div7">
        <a href={require("../assets/pdf/POLITICACALIDADDEVIDA.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.11')}</a>
        </div>
      </div>
      <div className="d-flex p-2 mar" id="tiposerv">
        <div className="div7">
        <a href={require("../assets/pdf/POLITICAANTICORRUPCION.pdf")} target="_blank" rel="noopener noreferrer" className="btn text-white" >{t('Pol.16')}</a>
        </div>
      </div>
      </div>
    </section>

  
    <section >  
    <div className="d-flex p-4 justify-content-around sizet" id="tiposerv">
    <div className="align-self-center ">
    <a href={require("../assets/pdf/CODIGODEETICA.pdf")} target="_blank" rel="noopener noreferrer" className="btn" >
      <img className="img-fluid" src={require("../assets/img/pol4.png")} alt="códigoética" />
      <p className="bo2 text-center">{t('Pol.12')}</p>
    </a>
    </div>
    <div className="align-self-center ">
    <a href={require("../assets/pdf/DECALOGO.pdf")} target="_blank" rel="noopener noreferrer" className="btn" >
      <img className="img-fluid" src={require("../assets/img/pol3.png")} alt="decálogo"/>
      <p className="bo2 text-center ">{t('Pol.13')}</p>
    </a>
    </div>
    <div className="align-self-center">
    <a href="/servicios" className="btn" data-toggle="modal" data-target="#quejas">
              
      <img className="img-fluid" src={require("../assets/img/pol2.png")} alt="quejas"/>
      <p className="bo2 text-center"> {t('Pol.14')}</p> 
    </a>

<div className="modal fade" id="quejas"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pol.14')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
              <FormCont/>
            <div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>
            </div>
            </div>
            </div>
            </div>
    </div>
    <div className="align-self-center">
    <a href={require("../assets/pdf/PROCEDIMIENTODEDENUNCIAS.pdf")} target="_blank" rel="noopener noreferrer" className="btn" >
      <img className="img-fluid" src={require("../assets/img/pol1.png")} alt="denuncias"/>
      <p className="bo2 text-center"> {t('Pol.15')}</p>
    </a>
    </div>
    </div>
    
  </section>
    
 </div>
  );
}
  
export default Sustentabilidad;