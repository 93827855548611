import React from 'react';
import { useTranslation } from 'react-i18next';

function Tiposerv() {

const { t} = useTranslation();

return (
    <div className="App">
        
      <div className="overlayt banim" id="bannerserv">  
      <img className="img-fluid" src={require("../assets/img/serviciosban.png")} alt="serviciobanner" />
      <div className="bottom-left bo">{t('Enca.3')}</div>
      </div>

    <section id="terrestre">

	 <div className="d-flex p-2 align-items-center justify-content-center bi" >
   
   <div className="d-flex p-2 align-items-center bi2 justify-content-end div9">
	 	 <div className="text-white picbi justify-content-center " >	

    		<img className="img-fluid" src={require("../assets/img/camion.png")} alt="camion"/>	
    	</div>	
    	<div className="namebi bo">
    	{t('Se.1')}
    	</div>
    </div>
    
      	<div className="div10 fontbi mx-auto">
      		<div className="bo">{t('Se.2')}</div>
			<li>{t('Se.3')}</li>
			<li className="mt-0 mb-0">{t('Se.4')}</li>
      <div className="ml-4 mr-0">{t('Se.5')} </div>
			   <div className="bo">{t('Se.6')}</div>
			<li className="mt-0 mb-0">{t('Se.3')}</li>
			<li className="mt-0 mb-0">{t('Se.7')}</li>
			
   		</div>

 	</div>    
    </section>

    <section id="marítimo">
	 <div className="d-flex p-2 align-items-center  grey text-white justify-content-center bi " >
	 	 <div className="d-flex p-2 bi2 align-items-center div9">
     <div className="text-white picbi" >	
    		<img className="img-fluid mt-3 mb-3" src={require("../assets/img/barco.png")} alt="marítimo"/>	
    	</div>	
    	<div className=" namebi bo" >
    	{t('Se.8')}
    	</div>
      </div>
      	<div className="text-white  fontbi mx-auto div10">
      		<li> {t('Se.9')}</li>
			<li> {t('Se.10')}</li>
			<li>{t('Se.11')}</li>
			<div className="ml-4">{t('Se.12')}</div>
      {/*<div className="ml-4"> {t('Se.13')}</div>
      <div className="ml-4">{t('Se.14')}</div>*/}
   		</div>
 	</div>    
    </section>

    <section id="aéreo">
	 <div className="d-flex p-2 align-items-center orange text-white justify-content-center bi"  >
   <div className="d-flex p-2 bi2 align-items-center div9">
	 	 <div className="text-white picbi" >	
    		<img className="img-fluid mt-3 mb-3" src={require("../assets/img/avionico.png")} alt="aéreo"/>	
    	</div>	
    	<div className=" namebi bo ">
    	{t('Se.15')}
    	</div>
      </div>
      	<div className="div10 fontbi mx-auto" >
      		<li>  {t('Se.16')}</li>
			<li> {t('Se.17')}</li>
			<li>Hand carrier</li>
			<li> Charter</li>
   		</div>
 	</div>    
    </section>

    <section id="tipocarga">
	 <div className="d-flex p-2  align-items-center justify-content-center bi" >
   <div className="d-flex p-2 bi2 align-items-center div9">
	 	 <div className="text-white picbi " >	
    		<img className="img-fluid mt-3 mb-3" src={require("../assets/img/cargaicon.png")} alt="cargaproyecto"/>	
    	</div>	
    	<div className="namebi bo">
    	{t('Se.18')}
    	</div>
      </div>
      	<div className="div10 fontbi mx-auto">
      		<li>{t('Se.19')}</li>
			<li>OW (Overweight), OS (Oversize)</li>
			<li>{t('Se.20')}</li>
   		</div>
 	</div>    
    </section>


    <section id="asesoría">
	 <div className="d-flex p-2 align-items-center grey text-white justify-content-center bi" >
   <div className="d-flex p-2 bi2 align-items-center div9">
	 	 <div className="text-white picbi" >	
    		<img className="img-fluid mt-3 mb-3 " src={require("../assets/img/asesoria.png")} alt="iconoasesoría" />	
    	</div>	
    	<div className=" namebi bo">
    	{t('Se.21')}
    	</div>
      </div>
      	<div className=" text-white div10 fontbi mx-auto">
      		<li>{t('Se.22')}</li>
			<li>{t('Se.23')}</li>
				<div className="ml-4">{t('Se.24')}</div>
				<div className="ml-4">{t('Se.25')}</div>
				<div className="ml-4">{t('Se.26')}</div>
   		</div>
 	</div>    
    </section>
    
    <section id="despacho">
	 <div className="d-flex p-2 align-items-center orange text-white justify-content-center bi" >
   <div className="d-flex p-2 bi2 align-items-center div9">
	 	 <div className="text-white picbi" >	
    		<img className="img-fluid mt-3 mb-3" src={require("../assets/img/despachoicon.png")} alt="iconoaduana"/>	
    	</div>	
    	<div className=" namebi bo">
    	{t('Se.27')}
    	</div>
      </div>
      	<div className="div10 text-white fontbi mx-auto">
          <li>{t('Se.28')}</li>
      		<div className="ml-4">{t('Se.29')}, Veracruz, {t('Se.30')}, Manzanillo, Lázaro Cárdenas, Laredo/Nuevo Laredo, Altamira,
           Mérida, Puerto Progreso, Cancún, Puerto Morelos, Ensenada, Tijuana/San Diego, León, Guadalajara</div> 
				<li>{t('Se.31')}</li><li> {t('Se.32')}</li>
				<li>{t('Se.33')}</li><div className="ml-4">{t('Se.34')}</div> 
   		</div>
 	</div>    
    </section>

    <section>
	 <div className="d-flex align-items-center p-2 justify-content-center bi" id="seguro">
   <div className="d-flex p-2 bi2 align-items-center div9">
	 	 <div className="text-white picbi" >	
    		<img className="img-fluid mt-3 mb-3" src={require("../assets/img/seguroicon.png")} alt="seguroicono" />	
    	</div>	
    	<div className="namebi bo">
    	{t('Se.35')}
    	</div>
      </div>
      	<div className="div10 fontbi mx-auto">
      		
          <p className=" text-justify mr-4 bo"> {t('Se.36')}</p>
          <p className=" text-justify mr-4 bo">{t('Se.37')}</p>
   		</div>
 	</div>    
    </section>


 </div>


  );
}
  
export default Tiposerv;