import React from 'react';
import FormInt from './FormInt.js';
import { useTranslation } from 'react-i18next';

function Servicio() {

const { t } = useTranslation();

return (
    <div className="App">
      <div className="overlayt banim">
     <img className="img-fluid" src={require("../assets/img/cotizacionban.png")} alt="bannerservicio"  />
   		<div className="bottom-left bo">{t('Enca.4')}</div>
      </div>
    <section className="container2 bo mt-4 mb-4">
    	<p>{t('Pr.1')}</p>
      
       <div className="text-center">
            

        <button type="button" className="btn btn-primary btn-xl  align-items-center mt-4 mb-4" data-toggle="modal" data-target="#cotizar">
          <h3 className="text-white">{t('Pr.2')}</h3>
        </button>

<div className="modal fade" id="cotizar"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pr.3')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
              <FormInt/>
            <div>
            </div>
            <div className="modal-footer orange">
              <button type="button" className="btn text-white bo" data-dismiss="modal">{t('Pol.17')}</button>
            </div>
            </div>
            </div>
            </div>
    </div>


    <p>{t('Pr.5')}</p>
    <div className="text-center ">
    
     <a className="butasi" href="https://api.whatsapp.com/send?phone=+524424790233&text=Necesito apoyo para realizar la cotización" target="_blank" rel="noopener noreferrer">
        <button type="button" className="btn btn-primary btn-xl  align-items-center mt-4 mb-4" >  <h3 className="text-white">{t('Pr.6')}</h3>
        </button></a>
    </div>
      
    </section>

    

     
    </div>


  );
}
  
export default Servicio;