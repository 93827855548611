import React from 'react';
import './App.css';
import Carruselboton from './components/Carruselboton.js'
import Carruselbanner from './components/Carruselbanner.js'

import { useTranslation } from 'react-i18next';

function App() {

const { t} = useTranslation();


return (
<div className="App">
      
      <header >
      <Carruselbanner />
      </header>


  <div className="bo fosi  mb-0 mt-4 container3"> {t('Parrafo.1')}</div>
 <div className="d-flex p-4 pt-0 mt-0 container5" id="tiposerv">
    <div className="align-self-center div6 mt-0">
      <img className="img-fluid" src={require("./assets/img/c11.png")} alt="ers" />
    </div>
    <div className="align-self-center div6 mt-0">
      <img className="img-fluid" src={require("./assets/img/c21.png")} alt="iata"  />
    </div>
    <div className="align-self-center div6 mt-0">
      <img className="img-fluid" src={require("./assets/img/c64.png")} alt="comercio" />
    </div>
    {/*<div className="align-self-center div6 mt-0">
      <img className="img-fluid" src={require("./assets/img/c41.png")} alt="camaracomercio" />
    </div>*/}
    </div>
    



<section className="grey  ">   
    
    <div className="d-flex justify-content-center">
      
      <div >
      <a className="btn" href="https://toc-tracking.riege.com/scope/track/" target="_blank" rel="noopener noreferrer">
      <span className="align-self-center text-white fosi">{t('Sesion.1')}</span>

        <img src={require("./assets/img/rastreo.png")} alt="botonlogin"  />
      
      </a>
      </div>
      
    </div>
    


  </section>

{/*<section className="orange  ">  
    <div className="d-flex p-3" id="tiposerv">
    <div className="align-self-center ">
    <a href="/servicios" className="btn" >
      <img className="img-fluid" src={require("./assets/img/camionblan.png")} />
      <h3 className="text-white"> Transporte Terrestre</h3>
    </a>
    </div>
    <div className="align-self-center ">
    <a href="/servicios" className="btn" >
      <img className="img-fluid" src={require("./assets/img/barcoblan.png")} />
      <h3 className="text-white"> Transporte Marítimo</h3>
    </a>
    </div>
    <div className="align-self-center ">
    <a href="/servicios" className="btn" >
      <img className="img-fluid" src={require("./assets/img/avionblan.png")} />
      <h3 className="text-white"> Transporte Aéreo</h3>
    </a>
    </div>
    </div>
    
  </section>*/}


  <section >
  <Carruselboton />
  </section>

 
         

</div>


  );
}
  

export default App;