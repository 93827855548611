import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './components/i18next.js'
import Encabezado from './components/Encabezado.js'
import {TinyButton as ScrollUpButton}  from "react-scroll-up-button";
import Pie from './components/Pie.js'
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'jquery/dist/jquery.slim.min.js';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap-social/bootstrap-social.css';

ReactDOM.render(
  <React.StrictMode>
  	<Suspense fallback={(<div>Cargando ~~~</div>)}>
  	<Encabezado/>

    <ScrollUpButton className="sett"/>
  	<Pie/>
  	</Suspense>
   

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
