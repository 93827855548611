import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import App from '../App';
import Nosotros from '../components/Nosotros.js';
import Contacto from '../components/Contacto.js';
import Servicio from '../components/Servicio.js';
import Tiposerv from '../components/Tiposerv.js';
import Sustentabilidad from '../components/Sustentabilidad.js';
import { useTranslation } from 'react-i18next';

function Encabezado() {
  
  const { t, i18n } = useTranslation();
  

  function handleClick(lang){
    
    i18n.changeLanguage(lang);
    
  }
  
return (

    <div className="App">
      <Router>
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top " >
        
              

              <a className=" logomov ml-auto " href="/"> 
          
              <img className="img-fluid logomov2" src={require("../assets/img/LOGOTOC1.png")} alt="logo" id="logoimage" />     
                </a>
               <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              </button>  
              
           <div className="container2">

          <div className="collapse navbar-collapse " id="navbarSupportedContent">

              <ul className="nav navbar-nav w-80">
              
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"> 

                <Link className="nav-link bo" to="/" >{t('Enca.1')}<span className="sr-only">(current)</span></Link>
              </li>  
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link className="nav-link bo" to="/nosotros">{t('Enca.2')}</Link>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link className="nav-link bo" to="/servicios">{t('Enca.3')}</Link>
              </li>
              <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link className="nav-link bo" to="/cotización">{t('Enca.4')} </Link>
              </li>
              <li className="nav-item bo" data-toggle="collapse" data-target=".navbar-collapse.show">
                <a className="nav-link" href="https://toc-tracking.riege.com/scope/track/" target="_blank" rel="noopener noreferrer">Login</a>
              </li>
              <li className="nav-item bo" data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link className="nav-link" to="/sustentabilidad">{t('Enca.5')}</Link>
              </li>
              <li className="nav-item bo" data-toggle="collapse" data-target=".navbar-collapse.show">
                <Link className="nav-link" to="/contacto">{t('Enca.6')}</Link> 
              </li>
              </ul>
             
              <li className="navbar-nav nav ml-auto " data-toggle="collapse" data-target=".navbar-collapse.show">
              
                <a onClick={()=>handleClick('es')} className="nav-link bo ml-0 mr-0" href="/#">
                  Es 
              </a>                 
                <a className="nav-link bo " href="/#">|  
              </a> 
              <a onClick={()=>handleClick('en')} className="nav-link bo " href="/#">En   
              </a> 
              </li> 
              
             
              <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link" href="https://www.linkedin.com/company/toc-logistics-mexico" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid" src={require("../assets/img/linkedin1.png")} alt="linkedinlogo" target="_blank" rel="noopener noreferrer"/>  
              </a>
              </li>
              <li className="nav-item" >
                <a className="nav-link" href="https://www.instagram.com/toclogistics.mexico" target="_blank" rel="noopener noreferrer" >
                  <img className="img-fluid" src={require("../assets/img/instagram1.png")} alt="instagramlogo" /> 
              </a>
              </li>
              <li className="nav-item ">
                <a className="nav-link" href="https://www.facebook.com/toclogisticsmx/" target="_blank" rel="noopener noreferrer">
                  <img className="img-fluid" src={require("../assets/img/facebook.png")} alt="facelogo" /> 
              </a>
              </li>
              </ul>
              
            </div>
          </div>
          
      </nav>

       <Switch>

          <Router exact path="/">
            <App />
            <Route path='/' component={App}>
              
              <Route path='nosotros' component={Nosotros} />
              <Route path='servicios' component={Tiposerv} />
              </Route>
          </Router>
          <Route  path="/nosotros">
            <Nosotros />
          </Route>
          <Route path="/servicios">
            <Tiposerv />
          </Route>
          <Route path="/cotización">
            <Servicio/>
          </Route>
          <Route path="/sustentabilidad">
            <Sustentabilidad />
          </Route>
          <Route path="/contacto">
            <Contacto/>
          </Route>
        </Switch>
</Router>
 


</div>


  );
}
  
export default Encabezado;