import React from 'react';
import { useTranslation } from 'react-i18next';



function Carruselbanner(lang) {

  const { i18n } = useTranslation();
  

  
 
  if(i18n.language==='es'){
   
  return (
    

    <div id="banner" className="carousel slide banim2" data-ride="carousel" data-interval="3000" >

    
    <div className="carousel-inner" role="listbox">
      <div className="carousel-item active" >
          <img className="img-fluid " src={require("../assets/img/lbanner5.png")} alt="Transporteaéreo"/>
          
       
      </div>
      
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/lbannerm4.png")} alt="TransporteMarítimo"/>
        
      </div>
      
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/lbanner3.png")} alt="Transporteterrestre"/>
        
      </div>
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/lbanner11.png")} alt="Comercioexterior"/>
        
      </div>
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/lbanner2.png")} alt="DespachoAduanal"/>
        
      </div>
    </div>
    <a className="carousel-control-prev" href="#banner" role="button" data-slide="prev">
          <span className="fa fa-angle-left" aria-hidden="true"></span>
          <span className="sr-only">Anterior</span>
        </a>
    <a className="carousel-control-next" href="#banner" role="button" data-slide="next">
          <span className="fa fa-angle-right" aria-hidden="true"></span>
          <span className="sr-only">Siguiente</span>
        </a>
  </div>

   

  );
}
else{

  return (
    

    <div id="banner" className="carousel slide banim2" data-ride="carousel">
    <ol className="carousel-indicators">
      <li data-target="#banner" data-slide-to="0" className="active"></li>
      <li data-target="#banner" data-slide-to="1"></li>
      <li data-target="#banner" data-slide-to="2"></li>
      <li data-target="#banner" data-slide-to="3"></li>
      <li data-target="#banner" data-slide-to="4"></li>
      
    </ol>
    <div className="carousel-inner" role="listbox">
      <div className="carousel-item active" >
          <img className="img-fluid " src={require("../assets/img/Airtransport.jpg")} alt="exteriora"/>
          
       
      </div>
      
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/Marinetransport.jpg")} alt="exteriorb"/>
        
      </div>
      
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/groundtransportation.jpg")} alt="loc"/>
        
      </div>
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/Tradeservices.jpg")} alt="loc"/>
        
      </div>
      <div className="carousel-item" >
      <img className="img-fluid" src={require("../assets/img/Customsclearance.jpg")} alt="loc"/>
        
      </div>
    </div>
    <a className="carousel-control-prev" href="#banner" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Anterior</span>
        </a>
    <a className="carousel-control-next" href="#banner" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Siguiente</span>
        </a>
  </div>

   

  );
}

}


export default Carruselbanner;