import React from 'react'
import Styles from './Styles'
import { Form, Field } from 'react-final-form'
import SelectInput from './SelectInput'
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';

function FormInt() {

const { t } = useTranslation();

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_j2qrkwf', 'template_necggkt', e.target, 'user_Ugi7xSBSthu6sw56rSiT8')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      swal({
  title: "Cotización enviada",
  text: "En breve nos pondremos en contacto con usted",
  icon: "success",
  button: "Cerrar",
});;
      e.target.reset();
  }

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const onSubmit = async values => {
  await sleep(300)
  
}

const Error = ({ name }) => (
  <Field name={name} subscription={{ error: true, touched: true }}>
    {({ meta: { error, touched } }) =>
      error && touched ? <span>{error}</span> : null
    }
  </Field>
)

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (value === is ? children : null)}
  </Field>
)

return (
  <Styles >
    <Form
      onSubmit={onSubmit}
      initialValues={{ }}
    >
      {({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={sendEmail}>
          <div  >
            <label>{t('Fo.1')} </label>
            <input  name="nombref" component="input" type="text" placeholder="Nombre Completo" required/>
            
          </div>
          <div>
            <label>{t('Fo.2')}</label>
            <input name="empresa" component="input" type="text" placeholder="Nombre de la compañía" required/>
            
          </div>
         
          <div>
            <label>{t('Fo.3')}</label>
            <input name="telefono" component="input" type="text" placeholder="Oficina" required/>
            
          </div>
          
          
          <div>
            <label>{t('Fo.5')}</label>
            <input name="email" component="input" type="email" placeholder="*****@****.com" required/>
            
          </div>
          <div>
            <label>{t('Fo.9')}</label>

            
            <Field name="servicio" component={SelectInput}>
               <option />
              <option value="Flete Aéreo">{t('Fo.10')}</option>
              <option value="Flete Marítimo">{t('Fo.11')}</option> 
              <option value="Flete Terrestre">{t('Fo.12')}</option> 
              <option value="Despacho Aduanal">{t('Se.27')}</option> 
              <option value="Asesorías">{t('Se.21')}</option> 
              
            </Field>
           
            <Error name="servicio" />
          </div>


          <Condition when="servicio" is="Flete Aéreo">
            <div>
              <label>{t('Fo.13')}</label>
              <input name="cantidadbultos" component="input" type="text" placeholder="Cantidad de bultos" required/>
              
              </div>
              <div>
              <label>{t('Fo.14')}</label>
              <input
                name="pesobrutoa" component="input" type="text" placeholder="Kilogramos" required />
              </div>
              <div>
              <label>{t('Fo.15')}</label>
              <input
                name="pesonetoa" component="input" type="text" placeholder="Kilogramos" required/>
              </div>
              <div>
              <label>{t('Fo.16')}</label>
              <input name="dimensiones" component="input" type="text" placeholder="Ancho x largo x alto (metros)" required/>
              </div>
              <div>
              <label>{t('Fo.18')}</label>
              <input name="tipocarga" component="input" type="text" placeholder="¿Qué desea transportar?" required/>
              </div>
              <div>
              <label>Incoterm</label>
              <Field name="incoterma" component={SelectInput} required>
               <option />
              <option value="FA">EXW</option><option value="FA9">FCA</option> 
              <option value="FA1">FAS</option><option value="FA8">FOB</option> 
              <option value="FA3">CFR</option> <option value="FAS7">CIF</option> 
              <option value="FA2">CPT</option> <option value="FA6">CIP</option> 
              <option value="FA4">DAP</option> <option value="FA5">DPU</option> <option value="FA10">DDP</option> 
              </Field>
              </div>

              <div>
              <label>{t('Fo.20')}</label>
              <input name="origen" component="input" type="text" placeholder="de la carga incluyendo código postal" required/>
              </div>
              <div>
              <label>{t('Fo.22')}</label>
              <input name="aeras" component="input" type="text" placeholder="" required/>
              </div>
              <div>
              <label>{t('Fo.24')}</label>
              <input name="aeral" component="input" type="text" placeholder="" required/>
              </div>
              <div>
              <label>{t('Fo.21')}</label>
              <input name="destino" component="input" type="text" placeholder="de la carga incluyendo código postal" />
              </div>

              <div>
              <label>{t('Fo.9')}</label>
              <div>
              <label>
                <Field name="tipoenvioa" component="input" type="radio" value="Estándar" />{' '} Estándar
              </label>
              <label>
                <Field name="tipoenvioa" component="input" type="radio" value="Prioridad" />{' '} Prioridad
              </label>
            </div>
              <Error name="servicio" />
              </div>
              
          </Condition>


          <Condition when="servicio" is="Flete Marítimo">
          <div>
            <label>Tipo Embarque</label>
            <Field name="tipoembarque" component={SelectInput} required>
               <option />
              <option value="FCL">FCL</option>
              <option value="LCL">LCL</option> 
            </Field>
          </div>
            <Condition when="tipoembarque" is="FCL">
              <div>
              <label>Incoterm</label>
              <Field name="incoterma" component={SelectInput} required>
               <option />
              <option value="FA11">EXW</option><option value="FA19">FCA</option> 
              <option value="FA12">FAS</option><option value="FA18">FOB</option> 
              <option value="FA13">CFR</option> <option value="FAS17">CIF</option> 
              <option value="FA14">CPT</option> <option value="FA16">CIP</option> 
              <option value="FA15">DAP</option> <option value="F20">DPU</option> <option value="FA21">DDP</option> 
              </Field>
              </div>
              <div>
            <label>Tipo de Contenedor</label>
            <div>
              <label>
                <Field
                  name="tipoconts1" component="input" type="checkbox" />{' '}20 DC
              </label>
                <Condition when="tipoconts1" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc1" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc1" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc1" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts2" component="input" type="checkbox" />{' '}40 DC
              </label>
              <Condition when="tipoconts2" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc2" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc2" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc2" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts3"  component="input"  type="checkbox" />{' '}40 HC
              </label>
              <Condition when="tipoconts3" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc3" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc3" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc3" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts4"  component="input"  type="checkbox" />{' '}20 REEFER
              </label>
              <Condition when="tipoconts4" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc4" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc4" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc4" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts5"  component="input"  type="checkbox" />{' '}40 REEFER
              </label>
              <Condition when="tipoconts5" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc5" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc5" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc5" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
              <Field
                  name="tipoconts6" component="input" type="checkbox" />{' '}40 HC REEFER
              </label>
              <Condition when="tipoconts6" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc6" component="input" type="text" placeholder="" radio/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc6" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc6" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts7" component="input" type="checkbox"  />{' '}20 OT
              </label>
              <Condition when="tipoconts7" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc7" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc7" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc7" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts8"  component="input"  type="checkbox" />{' '}40 OT
              </label>
              <Condition when="tipoconts8" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc8" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc8" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc8" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts9"  component="input"  type="checkbox"/>{' '}20 FR
              </label>
              <Condition when="tipoconts9" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc9" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc9" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc9" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
              <label>
                <Field name="tipoconts10"  component="input"  type="checkbox" />{' '}40 FR
              </label>
              <Condition when="tipoconts10" is={true}>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc10" component="input" type="text" placeholder="" requiredr/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc10" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc10" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
               <label>
                <Field name="tipocontso"  component="input"  type="checkbox" />{' '}Otro
              </label>
                <Condition when="tipocontso" is={true}>
                    <div><label>Tipo cntr</label><input className="ml-4" name="otrocnt" component="input" type="text" placeholder="" required /></div>
                    <div><label>{t('Fo.19')} </label><input className="ml-4" name="ctc11" component="input" type="text" placeholder="" required/></div>
                    <div><label>{t('Fo.14')}</label><input className="ml-2" name="pbtc11" component="input" type="text" placeholder="Kilogramos" required/></div>
                    <div><label>{t('Fo.15')}</label><input className="ml-3" name="pntc11" component="input" type="text" placeholder="Kilogramos" required/></div>
                </Condition>
            </div>
            </div>
                 
              
              <div>
              <label>{t('Fo.20')}</label>
              <input
                name="diror" component="input" type="text" placeholder="Incluya el código postal" required/>
              
              </div>
              <div>
              <label>Puerto</label>
              <input name="puertoom" component="input" type="text" placeholder="De origen" required/>
              </div>
              <div>
              <label>Puerto</label>
              <input name="puertoem" component="input" type="text" placeholder="De Llegada" required/>
              </div>
              <div>
              <label>{t('Fo.21')}</label>
              <input name="dirde" component="input" type="text" placeholder="Incluya el código postal" required/>
              
              </div>
              
              
            </Condition>
            <Condition when="tipoembarque" is="LCL">
              <div>
              <label>Incoterm</label>
              <Field name="incotermal" component={SelectInput} required>
               <option />
              <option value="FA">EXW</option><option value="FA9">FCA</option> 
              <option value="FA1">FAS</option><option value="FA8">FOB</option> 
              <option value="FA3">CFR</option> <option value="FAS7">CIF</option> 
              <option value="FA2">CPT</option> <option value="FA6">CIP</option> 
              <option value="FA4">DAP</option> <option value="FA5">DPU</option> <option value="FA10">DDP</option> 
              </Field>
              </div>
              <div>
              <label>{t('Fo.20')}</label>
              <input
                name="dirorl" component="input" type="text" placeholder="Incluya código postal" required/>
              
              </div>
              <div>
              <label>Puerto</label>
              <input name="pol" component="input" type="text" placeholder="De origen" required/>
              </div>
              <div>
              <label>Puerto</label>
              <input name="pdl" component="input" type="text" placeholder="De Llegada" required/>
              </div>
              <div>
              <label>{t('Fo.21')}</label>
              <input name="dirdel" component="input" type="text" placeholder="Incluya código postal" required/>
              
              </div>
              <div>
              <label>{t('Fo.18')}</label>
              <input name="prol" component="input" type="text" placeholder="" required />
              
              </div>
              <div>
              <label>{t('Fo.14')}</label>
              <input name="pel" component="input" type="text" placeholder="Kilogramos" required/>
              
              </div>
              <div>
              <label>{t('Fo.15')}</label>
              <input name="peln" component="input" type="text" placeholder="Kilogramos" required/>
              
              </div>
              <div>
              <label>{t('Fo.19')}</label>
              <input name="numerobultol" component="input" type="text" placeholder="Número de bultos" required />
              
              </div> 
              <div>
              <label>{t('Fo.16')}</label>
              <input name="dimensionesml" component="input" type="text" placeholder="Ancho x largo x alto (metros)" required/>
              
              </div>
               
            </Condition>
          </Condition>
          
          <Condition when="servicio" is="Flete Terrestre">
          <div>
            <label>{t('Fo.23')} </label>
            <Field name="tipotransporte" component={SelectInput} required>
              <option />
              <option value="LTL">LTL</option>
              <option value="FTL">FTL</option> 
            </Field>
          </div>
            <Condition when="tipotransporte" is="LTL">
              <div>
              <label>{t('Fo.4')}</label>
              <input name="direcl" component="input" type="text" placeholder="De recolección" required/>
              
              </div>
              <div>
              <label>{t('Fo.4')}</label>
              <input name="direnl" component="input" type="text" placeholder="Entrega" required />
              
              </div>
              <div>
              <label>Número</label>
              <input name="numerobultolt" component="input" type="text" placeholder="De bulto" required/>
              
              </div>
              
              <div>
              <label>Peso</label>
              <input name="pesoporbultol" component="input" type="text" placeholder="Por bulto" required/>
              
              </div>
              <div>
              <label>{t('Fo.16')}</label>
              <input name="dimensionestl" component="input" type="text" placeholder="Por bulto" required/>
              
              </div>
            
               <div>
              <label>{t('Fo.17')}</label>
              <input name="productl" component="input" type="text" placeholder="" required/>
              
              </div>
             
               <div>
              <label>Frontera</label>
              <input name="frontera" component="input" type="text" placeholder="De cruce" required/>
             
              </div>
              <div>
              <label>Incoterm</label>
              <input name="incotermtl" component="input" type="text" placeholder="" required />
              
              </div> 
            </Condition>
            <Condition when="tipotransporte" is="FTL">
              
              <div>
              <label>{t('Fo.4')}</label>
              <input name="dirortf" component="input" type="text" placeholder="De recolección" required/>
              
              </div>
              <div>
              <label>{t('Fo.4')}</label>
              <input name="dirdetf" component="input" type="text" placeholder="Entrega" required/>
              
              </div>
              <div>
              <label>{t('Fo.23')}</label>
              <input name="tiun" component="input" type="text" placeholder="De unidad" required />
              
              </div>
              <div>
              <label>{t('Fo.9')}</label>
              <div>
              <label>
                <Field name="tipoenvioft" component="input" type="radio" value="Directo" />{' '} Directo
              </label>
              <label>
                <Field name="tipoenvioft" component="input" type="radio" value="Transbordo" />{' '} Transbordo
              </label>
            </div>
              <Error name="servicio" />
              </div>
               <div>
              <label>Número</label>
              <input name="numerobultoft" component="input" type="text" placeholder="De bultos" required/>
              
              </div>
              <div>
               <label>Peso</label>
              <input name="pelft" component="input" type="text" placeholder="Por Bulto" required/>
              
              </div>
              <div>
              <label>{t('Fo.16')}</label>
              <input name="dimensionesft" component="input" type="text" placeholder="Ancho x largo x alto por bulto" required/>
              
              </div>
              <div>
              <label>{t('Fo.17')}</label>
              <input name="producft" component="input" type="text" placeholder="" required />
              
              </div>
              <div>
              <label>Frontera</label>
              <input name="fronteraf" component="input" type="text" placeholder="De cruce" required/>
              
              </div> 
              <div>
              <label>Incoterm</label>
              <input name="incotermft" component="input" type="text" placeholder="" required/>
              
              </div> 
            </Condition>
          </Condition>       
          
          
          <Condition when="servicio" is="Despacho Aduanal">
            <div>
              
              <label>Aduana</label>
              <input name="Aduana" component="input" type="text" placeholder="" required/>
               
              </div>
              <div>
              <label>
                <Field name="tipoenvioa" component="input" type="radio" value="Directo" />{' '} Importación
              </label>
              <label>
                <Field name="tipoenvioa" component="input" type="radio" value="Transbordo" />{' '} Exportación
              </label>
            </div>
              <div>
              <label>Descripción</label>
              <input name="Descripcion" component="input" type="text" placeholder="Describa la mercancía" required/>
              </div>
              <div>
              <label>Uso</label>
              <input name="uso" component="input" type="text" placeholder="" required />
               
              </div>
              <div>
              <label>Función</label>
              <input name="Funcion" component="input" type="text" placeholder="" required/>
               
              </div>
              <div>
              <label>Composición</label>
              <input name="Composicion" component="input" type="text" placeholder="" required/>
               
              </div>
              <div>
              <label>Ficha Técnica</label>
              <input name="Fichatecnica" component="input" type="text" placeholder="De la mercancía" required />
               
              </div>
              <div>
              <label>Lugar</label>
              <input name="Lugarembarque" component="input" type="text" placeholder="De embarque" required/>
               
              </div>
              
              
          </Condition>


          <Condition when="servicio" is="Asesorías">
            <div>
              
              <label>Seleccione una opción</label>
              <div>
              <label>
                <Field name="Asesorias" component="input" type="radio" value="Comercio Exterior" />{' '} Comercio exterior
              </label>
              <label>
                <Field name="Asesorias" component="input" type="radio" value="Legal Aduanero" />{' '} Legal Aduanero
              </label>
              <label>
                <Field name="Asesorias" component="input" type="radio" value="Clasificación Arancelaria" />{' '} Clasificación Arancelaria
              </label>
              <label>
                <Field name="Asesorias" component="input" type="radio" value="Despacho Aduanal" />{' '} Despacho Aduanal
              </label>
              <label>
                <Field name="Asesorias" component="input" type="radio" value="Cambios de Régimen" />{' '} Cambios de Régimen
              </label>
              <label>
                <Field name="Asesorias" component="input" type="radio" value="Regularizaciones" />{' '} Regularizaciones
              </label>
              <label>
                <Field name="Asesorias" component="input" type="radio" value="Operaciones Virtuales" />{' '} Operaciones Virtuales
              </label>
            </div>
            </div>
          </Condition>
           <div>
           
            <label>{t('Fo.26')}</label>
            <div>{'   '}{t('Fo.25')}{'  '}
            <Field name="extra1" component="input" type="checkbox" />
            </div>
          </div>
          <Condition when="extra1" is={true}>
            <div>
              <label>{t('Fo.27')}</label>
              <Field
                name="especificaex"
                component="textarea"
                type="text"
                placeholder="Comentarios o especificaciones sobre su envío" required
              />
              <Error name="especificaex" />
            </div>
          </Condition>
          
          <div className="buttons">
            <button type="submit"  disabled={submitting}>
              {t('Fo.7')}
            </button>
          </div>
         
        </form>
      )}
    </Form>
  </Styles>
)
      }

export default FormInt;
