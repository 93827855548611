import React from 'react';
import { useTranslation } from 'react-i18next';

function Nosotros() {

const { t} = useTranslation();

  

return (
    <div className="App">
      
       <div className="overlayt banim">  
      <img className="img-fluid" src={require("../assets/img/nosotrosban.png")} alt="bannernosotros" />
      <div className="bottom-left bo">{t('Nos.1')}</div>
      </div>

    <section>

	 <div className="d-flex p-2 orange text-white" id="tiposerv">
    		<div className="align-self-center div1 bo">
      		<h3>{t('Nos.2')}</h3>
    </div>
    <div className="align-self-center div2 conbi2 orange text-white">
      {t('Nos.3')}
    </div>
 </div>
     
    </section>
    
<section>
<div className="d-flex" id="tiposerv">
    <div className="align-self-center div1 bo">
      <h3>{t('Nos.4')}</h3>
    </div>
    <div className=" div2 conbi2">
     {t('Nos.5')}
    </div>
 </div>
 </section>

 <section>
<div className="d-flex p-2 text-white grey" id="tiposerv">
    <div className="align-self-center div1 bo ">
      <h3>{t('Nos.6')}</h3>
    </div>
    <div className="align-self-center div2 ">
     <h4>{t('Nos.7')}</h4>
		<p >{t('Nos.8')}</p>
	<h4>{t('Nos.9')}</h4>
		<p>{t('Nos.10')}</p>
	<h4>{t('Nos.11')}</h4>
		<p>{t('Nos.12')}</p>
	<h4>{t('Nos.13')}</h4>
		<p>{t('Nos.14')}</p>
	<h4>{t('Nos.15')}</h4>
		<p>{t('Nos.16')} </p>
	<h4>{t('Nos.17')}</h4>
		<p>{t('Nos.18')}</p>
    </div>
 </div>
 </section>

 </div>


  );
}
  
export default Nosotros;