import React from "react";
import Carousel, { consts } from 'react-elastic-carousel';
import Item from "./Item";
import { Button} from 'react-bootstrap';
import FormInt from './FormInt.js';
import { useTranslation } from 'react-i18next';

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 1200, itemsToShow: 3 },
  
];



function  Carruselboton() {

const { t } = useTranslation();

return (
    
     
      <div className="carruselbot orange">
    
        <Carousel breakPoints={breakPoints} renderArrow={({ type, onClick, isEdge })=> {
      const pointer = type === consts.PREV ? <img src={require("../assets/img/flechaizq.png")} alt="iz"/> : <img src={require("../assets/img/flechader.png")} alt="de"/>
      return (
        <Button variant="flat" onClick={onClick} disabled={isEdge}>
          {pointer}
        </Button>
      )
    }} className="orange">
          
          <div>
          <Item>
            <a href="/#" className="btn" data-toggle="modal" data-target="#terrestre">
             <img className="img-fluid resi" src={require("../assets/img/camionblan.png")} alt="terrestre" />
                <h3 className="text-white"> {t('Se.1')}</h3>
                    </a>
          </Item>
          <div className="modal fade" id="terrestre"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-justify ml-4 mr-4 mt-4">
              
              {t('Se.38')}
              <hr/>
              <div className="align-self-center ml-4 mt-4" >
             <p className="bo mb-2 "> {t('Se.2')} </p>
      <li>{t('Se.3')}</li>
      <li className="mt-0 mb-0">{t('Se.4')}</li>
      {/*<div className="ml-4"> {t('Se.5')}</div>*/}
      <hr/>
         <p className="bo mb-2 "> {t('Se.6')} </p>
      <li className="mt-0 mb-0">{t('Se.3')}</li>
      <li className="mt-0 mb-0">{t('Se.7')}</li>
            </div><div></div>
            <div className="modal-footer">
            <button  type="button" className="btn btn-secondary" data-toggle="modal" data-target="#cotizar">{t('Pr.4')}</button>
            <div className="modal fade" id="cotizar"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content"><div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pr.3')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button></div><FormInt/><div></div><div className="modal-footer orange">
              <button type="button" className="btn text-white bo" data-dismiss="modal">{t('Pol.17')}</button></div></div></div></div>
              <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>
            </div></div></div></div></div></div>
            
            <div>
            <Item><a href="/#" className="btn" data-toggle="modal" data-target="#marítimo">
                <img className="img-fluid resi" src={require("../assets/img/barcoblan.png")} alt="botonmarítimo"/>
                <h3 className="text-white"> {t('Se.8')}</h3>
                </a></Item>
                <div className="modal fade" id="marítimo"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                  <div className="modal-body text-justify ml-4 mr-4 mt-4">
              {t('Se.39')}
              <hr/>
                   <div className="align-self-center ml-4 mr-4 mt-4" >
                      <li> {t('Se.9')}</li>
                      <li> {t('Se.10')}</li>
                      <li>{t('Se.11')}</li>
                      <div className="ml-4">{t('Se.12')}</div>
                      {/*<div className="ml-4"> {t('Se.13')}</div>
                      <div className="ml-4">{t('Se.14')}</div>*/}
                </div><div></div>
                <div className="modal-footer">
                <button  type="button" className="btn btn-secondary" data-toggle="modal" data-target="#cotizar2">{t('Pr.4')}</button>
            <div className="modal fade" id="cotizar2"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content"><div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pr.3')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button></div><FormInt/><div></div><div className="modal-footer orange">
              <button type="button" className="btn text-white bo" data-dismiss="modal">{t('Pol.17')}</button></div></div></div></div>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>
                </div></div></div></div></div>
            </div>

          <div>
            <Item><a href="/#" className="btn" data-toggle="modal" data-target="#aéreo">
            <img className="img-fluid resi" src={require("../assets/img/avionblan.png")} alt="botonaéreo"/>
            <h3 className="text-white"> {t('Se.15')}</h3>
            </a></Item>
                  <div className="modal fade" id="aéreo"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">.
                  <div className="modal-body text-justify ml-4 mr-4 mt-4">
              {t('Se.40')}
              <hr/>
                   <div className="align-self-center ml-4 mt-4" >
                      <li> {t('Se.16')}</li>
                      <li> {t('Se.17')}</li>
                      <li>Hand carrier</li>
                      <li> Charter</li>
                </div><div></div>
                <div className="modal-footer">
                  <button  type="button" className="btn btn-secondary" data-toggle="modal" data-target="#cotizar3">{t('Pr.4')}</button>
            <div className="modal fade" id="cotizar3"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content"><div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pr.3')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button></div><FormInt/><div></div><div className="modal-footer orange">
              <button type="button" className="btn text-white bo" data-dismiss="modal">{t('Pol.17')}</button></div></div></div></div>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>

                </div></div></div></div></div>
          </div>

          <div>
            <Item><a href="/#" className="btn" data-toggle="modal" data-target="#tipocarga">
            <img className="img-fluid resi" src={require("../assets/img/cargablan.png")} alt="botoncarga" />
            <h3 className="text-white"> {t('Se.18')}</h3>
            </a></Item>
            <div className="modal fade" id="tipocarga"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                  <div className="modal-body text-justify ml-4 mr-4 mt-4">
              {t('Se.41')}
              <hr/>
                   <div className="align-self-center mt-4" >
                      <li>{t('Se.19')}</li>
                      <li>OW (Overweight), OS (Oversize)</li>
                      <li>{t('Se.20')}</li>
                </div><div></div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>
                </div></div></div></div></div>
          </div>

          <div>
            <Item><a href="/#" className="btn" data-toggle="modal" data-target="#asesoría">
            <img className="img-fluid resi" src={require("../assets/img/asesoriablan.png")} alt="botonasesoría"/>
            <h3 className="text-white"> {t('Se.21')}</h3>
            </a></Item>
          <div className="modal fade" id="asesoría"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                  <div className="modal-body text-justify ml-4 mr-4 mt-4">
              {t('Se.42')}
              <hr/>
                   <div className="align-self-center mt-4" >
                      <li className="ml-0 mr-4">{t('Se.22')}</li>
                      <li className="bo">{t('Se.23')}</li>
                        <div className="ml-4">{t('Se.24')}</div>
                        <div className="ml-4">{t('Se.25')}</div>
                        <div className="ml-4">{t('Se.26')}</div>
                </div><div></div>
                <div className="modal-footer">
                <button  type="button" className="btn btn-secondary" data-toggle="modal" data-target="#cotizar4">{t('Pr.4')}</button>
            <div className="modal fade" id="cotizar4"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content"><div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pr.3')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button></div><FormInt/><div></div><div className="modal-footer orange">
              <button type="button" className="btn text-white bo" data-dismiss="modal">{t('Pol.17')}</button></div></div></div></div>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>
                </div></div></div></div></div>
          </div>

          <div>
            <Item><a href="/#" className="btn" data-toggle="modal" data-target="#despacho">
           <img className="img-fluid resi" src={require("../assets/img/aduanablan.png")} alt="botondespacho"/>
            <h3 className="text-white"> {t('Se.27')}</h3>
            </a></Item>
          <div className="modal fade" id="despacho"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                  <div className="modal-body text-justify ml-4 mr-4 mt-4">
              {t('Se.43')}
              <hr/>
                   <div className="align-self-center ml-4 mr-4 mt-4" >
                      <li className="bo">{t('Se.28')}</li>
                        <div className="ml-4">{t('Se.29')}, Veracruz, {t('Se.30')}, Manzanillo, Lázaro Cárdenas, Laredo/Nuevo Laredo, Altamira,
                         Mérida, Puerto Progreso, Cancún, Puerto Morelos, Ensenada, Tijuana/San Diego, León, Guadalajara</div> 
                      <li className="bo">{t('Se.31')}</li><li className="bo"> {t('Se.32')}</li>
                      <li className="bo">{t('Se.33')}</li><div className="ml-4">{t('Se.34')}</div> 
                </div><div></div>
                <div className="modal-footer">
                <button  type="button" className="btn btn-secondary" data-toggle="modal" data-target="#cotizar5">{t('Pr.4')}</button>
            <div className="modal fade" id="cotizar5"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content"><div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle bo">{t('Pr.3')}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button></div><FormInt/><div></div><div className="modal-footer orange">
              <button type="button" className="btn text-white bo" data-dismiss="modal">{t('Pol.17')}</button></div></div></div></div>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>
                </div></div></div></div></div>
          </div>
            
          <div>
            <Item><a href="/#" className="btn" data-toggle="modal" data-target="#seguro">
            <img className="img-fluid resi" src={require("../assets/img/seguroblan.png")} alt="botonseguro"/>
            <h3 className="text-white"> {t('Se.35')}</h3>
            </a></Item> 
            <div className="modal fade" id="seguro"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                   <div className="align-self-center ml-4 mr-4 mt-4" >
                      <p className=" text-justify mr-4 bo">{t('Se.36')} </p>
                      <p className=" text-justify mr-4 bo">{t('Se.37')}</p>
                </div><div></div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">{t('Pol.17')}</button>
                </div></div></div></div>
          </div>


        </Carousel>
       
      </div>
   
    
  );
}
  
export default Carruselboton;