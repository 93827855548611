import React from 'react'
import Styles from './Styles'
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';

function FormCont() {

const { t } = useTranslation();

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_j2qrkwf', 'template_bxmdlno', e.target, 'user_Ugi7xSBSthu6sw56rSiT8')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      swal({
  title: "Hemos recibido su queja o sugerencia",
  text: "Esperamos brindarle siempre el mejor servicio.",
  icon: "success",
  button: "Cerrar",
});;
      e.target.reset()
  }

return (
  <Styles >
    
    <form
          onSubmit={
              sendEmail}
        >
          <div >
            <label>{t('Fo.1')} </label>
            <input  name="nombre" component="input" type="text" placeholder=""  required />
            
          </div>
          <div>
            <label>{t('Fo.2')}</label>
            <input name="empresa" component="input" type="text" placeholder="" required/>
            
          </div>         
          <div>
            <label>{t('Fo.3')}</label>
            <input name="celular" component="input" type="text" placeholder="" required/>
            
          </div>
          <div>
            <label>{t('Fo.4')}</label>
            <input name="Direccion" component="input" type="text" placeholder="" required/>
            
          </div>
          <div>
            <label>{t('Fo.5')}</label>
            <input name="email" component="input" type="text" placeholder="*****@****.com" required/>
            
          </div>

          
            <div>
              <label>{t('Fo.6')}</label>
              <input
                name="Mensaje"
                component="textarea"
                placeholder=""

              />
              
            </div>
          <div className="buttons">
            <button type="submit" >
              {t('Fo.7')}
            </button>
            
          </div>
          
        </form>
    
  </Styles>
)
      }

export default FormCont;
